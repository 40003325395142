<template>
  <div class="column q-mt-md" v-for="(item_event, index) in data" :key="index">
    <div class="row">
      <q-icon class="text-h5" name="event" />
      <span class="q-ml-xs text-bold">
        {{ displayTimeFormat(item_event[0], 'YYYY-MM-DD', 'MMMM Do, YYYY') }}
      </span>
    </div>
    <div class="column" v-for="(item, key) in item_event[1]" :key="key">
      <div class="row q-ml-md">
        <span class="q-ml-lg q-mt-md q-mb-md text-bold text-caption text-capitalize">
          <template v-if="item.campaign_type">
            {{ $t('label.' + item.campaign_type + '_messages') }} - {{ item.campaign_name }}
          </template></span
        >
      </div>
      <div class="row q-col-gutter-sm text-body2" v-for="(event, i) in item.events" :key="i">
        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-4" style="margin-top: 16px">
          {{ displayTimeFormat(event.time, 'HH:mm:ss', 'h:mm:ss A') }}
        </div>
        <div
          class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-8 border-bottom"
          :class="i === 0 ? 'border-top' : ''"
        >
          <q-expansion-item :label="$t('label.users.' + event.event_type)" @show="getContentURL(event)">
            <q-card>
              <q-card-section class="q-pl-none q-pr-none q-pt-md q-pb-md">
                <q-table :rows="event.event_data" flat bordered hide-header hide-bottom>
                  <template v-slot:body="props">
                    <q-tr :props="props" :index="props.rowKey">
                      <template
                        v-if="
                          props.row.name == 'sent_free_message' &&
                          (props.row.resource_type == 'video' || props.row.resource_type == 'audio')
                        "
                      >
                        <q-td v-if="props.row.resource_type == 'video'" class="text-bold" style="width: 100px">{{
                          $t('label.users.video')
                        }}</q-td>
                        <q-td v-else class="text-bold" style="width: 100px">{{ $t('label.users.audio') }}</q-td>
                        <q-td class="break-spaces">
                          <q-video
                            v-if="props.row.url !== ''"
                            :src="props.row.url"
                            style="height: 150px; width: 350px"
                          />
                          <q-inner-loading :showing="props.row.loading">
                            <q-spinner-gears size="40px" color="grey" />
                          </q-inner-loading>
                        </q-td>
                      </template>

                      <template v-else-if="props.row.name == 'sent_free_message' && props.row.resource_type == 'image'">
                        <q-td class="text-bold" style="width: 100px">{{ $t('label.users.image') }}</q-td>
                        <q-td class="break-spaces">
                          <q-img
                            v-if="props.row.url !== ''"
                            :src="props.row.url"
                            :ratio="1"
                            spinner-color="white"
                            style="height: 100px; max-width: 100px"
                          />
                          <q-inner-loading :showing="props.row.loading">
                            <q-spinner-gears size="40px" color="grey" />
                          </q-inner-loading>
                        </q-td>
                      </template>

                      <template v-else-if="props.row.name == 'sent_free_message' && props.row.resource_type == 'file'">
                        <q-td class="text-bold" style="width: 100px">{{ $t('label.users.other_file') }}</q-td>
                        <q-td class="break-spaces">
                          <a v-if="props.row.url !== ''" :href="props.row.url" :download="props.row.value">{{
                            props.row.value
                          }}</a>

                          <q-inner-loading :showing="props.row.loading">
                            <q-spinner-gears size="40px" color="grey" />
                          </q-inner-loading>
                        </q-td>
                      </template>
                      <template v-else-if="props.row.name == 'registered_channel'"> </template>
                      <template v-else>
                        <q-td class="text-bold" style="width: 100px">{{ $t('label.users.' + props.row.name) }}</q-td>
                        <q-td class="break-spaces"> {{ props.row.value }}</q-td>
                      </template>
                    </q-tr>
                    <q-tr>
                      <q-td class="text-bold" style="width: 100px">{{ $t('label.users.created_at') }}</q-td>
                      <q-td class="break-spaces"> {{ event.created_at }}</q-td>
                    </q-tr>
                    <q-tr>
                      <q-td class="text-bold" style="width: 100px">{{ $t('label.users.user_id') }}</q-td>
                      <q-td class="break-spaces"> {{ event.user_id }}</q-td>
                    </q-tr>
                    <q-tr v-if="item.campaign_type != ''">
                      <q-td class="text-bold" style="width: 100px">{{ $t('label.users.campaign_type') }}</q-td>
                      <q-td class="break-spaces"> {{ item.campaign_type }}</q-td>
                    </q-tr>
                    <q-tr v-if="item.campaign_type != ''">
                      <q-td class="text-bold" style="width: 100px">{{ $t('label.users.campaign_id') }}</q-td>
                      <q-td class="break-spaces"> {{ event.campaign_id }}</q-td>
                    </q-tr>
                    <q-tr v-if="item.campaign_type != ''">
                      <q-td class="text-bold" style="width: 100px">{{ $t('label.users.campaign_name') }}</q-td>
                      <q-td class="break-spaces"> {{ item.campaign_name }}</q-td>
                    </q-tr>
                    <q-tr v-if="event.event_type != 'registered_channel'">
                      <q-td class="text-bold" style="width: 100px">{{ $t('label.users.resource_type') }}</q-td>
                      <q-td class="break-spaces"> {{ event.resource_type }}</q-td>
                    </q-tr>
                    <q-tr>
                      <q-td class="text-bold" style="width: 100px">{{ $t('label.users.event_type') }}</q-td>
                      <q-td class="break-spaces"> {{ event.event_type }}</q-td>
                    </q-tr>
                    <q-tr>
                      <q-td class="text-bold" style="width: 100px" v-if="event.event_type == 'registered_channel'">{{
                        $t('label.users.source_name')
                      }}</q-td>
                      <q-td class="text-bold" style="width: 100px" v-else>{{ $t('label.users.event_value') }}</q-td>
                      <q-td class="break-spaces"> {{ event.event_value }}</q-td>
                    </q-tr>
                  </template>
                </q-table>
              </q-card-section>
            </q-card>
          </q-expansion-item>
        </div>
      </div>
    </div>
  </div>
  <div class="q-pa-md text-center">
    <span class="text-body2" v-if="lastActivity">
      {{ $t('label.users.since') }}
      {{ displayTimeFormat(lastActivity.created_at, 'YYYY-MM-DD HH:mm:ss', 'MMMM Do, h:mm A') }}
    </span>
    <span class="text-body2" v-else>{{ $t('messages.no_data_available') }}</span>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import moment from 'moment/moment'
import { IActivityLog } from '@/utils/types'
import { ACTION_ACTIVITY_LOG } from '@/store/actions'

@Options({})
export default class ActivityLog extends Vue {
  @Prop()
  data!: {}[]

  @Prop()
  lastActivity!: {}

  get selectedAppId() {
    return this.$route.params.app_id
  }

  displayTimeFormat(value, inputFormat, outputFormat) {
    return moment(value, inputFormat).format(outputFormat)
  }

  async getContentURL(item) {
    // data.event_value === 'video' || data.event_value === 'image' || data.event_value === 'file'
    if (
      (item.resource_type === 'image' ||
        item.resource_type === 'video' ||
        item.resource_type === 'audio' ||
        item.resource_type === 'file') &&
      item.event_data[0].url === ''
    ) {
      item.event_data[0].loading = true
      const url = await this.getContentData(item)
      item.event_data[0].url = url
      item.event_data[0].loading = false
    }
  }

  async getContentData(data: IActivityLog) {
    let url = ''
    if (data.resource_id && data.resource_type === 'image') {
      const val = await this.$store.dispatch(ACTION_ACTIVITY_LOG.CONTENT_ARRAY_BUFFER, {
        app_id: this.selectedAppId,
        resource_id: data.resource_id,
        is_test_mode: data.is_test_mode,
      })
      const base64Data = btoa(String.fromCharCode(...new Uint8Array(val)))
      url = `data:image/jpeg;base64,${base64Data}`
    } else if (data.resource_id && (data.resource_type === 'video' || data.resource_type === 'audio')) {
      const videoBlob = await this.$store.dispatch(ACTION_ACTIVITY_LOG.CONTENT_BLOD, {
        app_id: this.selectedAppId,
        resource_id: data.resource_id,
        is_test_mode: data.is_test_mode,
      })
      url = URL.createObjectURL(videoBlob)
    } else if (data.resource_id && data.resource_type === 'file') {
      const blob = await this.$store.dispatch(ACTION_ACTIVITY_LOG.CONTENT_BLOD, {
        app_id: this.selectedAppId,
        resource_id: data.resource_id,
        is_test_mode: data.is_test_mode,
      })
      url = URL.createObjectURL(blob)
    }

    return url
  }
}
</script>

<style scoped lang="scss">
.border-top {
  border-top: 1px solid #d0d2e0;
}
.border-bottom {
  border-bottom: 1px solid #d0d2e0;
}
:deep(.q-expansion-item .q-item) {
  padding-left: 0;
  padding-right: 0;
}
:deep(.q-expansion-item__container .q-hoverable:hover .q-focus-helper) {
  background: inherit;
  opacity: 0;
}
:deep(.col-xs-12 label.items-start) {
  width: 100% !important;
  margin-right: 0;
  margin-bottom: 8px;
}
</style>
