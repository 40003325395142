import { render } from "./ActivityLog.vue?vue&type=template&id=5435ba70&scoped=true"
import script from "./ActivityLog.vue?vue&type=script&lang=ts"
export * from "./ActivityLog.vue?vue&type=script&lang=ts"

import "./ActivityLog.vue?vue&type=style&index=0&id=5435ba70&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-5435ba70"

export default script
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QVideo from 'quasar/src/components/video/QVideo.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerGears from 'quasar/src/components/spinner/QSpinnerGears.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QIcon,QExpansionItem,QCard,QCardSection,QTable,QTr,QTd,QVideo,QInnerLoading,QSpinnerGears,QImg,QItem});
